import React, { useState, useEffect, useRef, useCallback } from 'react'
import styles from 'components/votes/lock-vote.module.scss'
import { Answer } from 'types/answers'
import { SATOSHI_UNIT } from 'lib/bsv-util'
import useStore from 'state/knovStore'
import { updateCachedAnswer } from 'state/cache'
import api from 'api/api'
import { BsvWalletType, initWallet } from 'wallets/wallets'
import { SignalType } from 'types/signals'
import { SignalOptionsButton } from 'components/votes/buttons/SignalOptionsButton'
import { SignalOptionsModal } from './modals/SignalOptionsModal'
import { SignalButton } from './buttons/SignalButton'
import useBlockheight from 'api/useBlockheight'
import useWalletDetails from 'api/useWalletDetails'

/** Time window in milliseconds for grouping rapid lock clicks into a single batch */
const LOCK_REQUEST_BATCH_WINDOW_MS = 1000

// Main component remains mostly the same but uses new tab components
export default function LockVote({
    answer,
    postLockRef,
}: {
    answer: Answer
    postLockRef: React.RefObject<HTMLDivElement>
}) {
    const walletType = useStore(state => state.currentUserBsvWalletType) || BsvWalletType.SHUALLET
    const { data: walletDetailsData } = useWalletDetails(walletType)

    const currentUserBsvBalance = walletDetailsData?.balance || 0
    const currentUserBsvAddress = walletDetailsData?.address || ''
    const wallet = walletDetailsData?.wallet

    const openWalletModal = useStore(state => state.actions.modalActions.openWalletModal)
    const blockheight = useBlockheight()

    const upvalueSats = useStore(state => state.upvalueSats)
    const lockSats = useStore(state => state.lockSats)
    const lockBlocks = useStore(state => state.lockBlocks)

    const [customUpvalueSats, setCustomUpvalueSats] = useState<number | null>(upvalueSats)
    const [customLockSats, setCustomLockSats] = useState<number | null>(lockSats)
    const [customBlocks, setCustomBlocks] = useState<number>(lockBlocks)

    useEffect(() => {
        setCustomUpvalueSats(upvalueSats)
        setCustomLockSats(lockSats)
    }, [upvalueSats, lockSats])

    const userBsvUnit = useStore(state => state.bsvUnit)

    const [showBsvModal, setShowBsvModal] = useState(false)

    const quickLockRef = React.useRef(null)

    const animateLock = (ref, scale = 1.5) => {
        const duration = 100
        const comp = ref.current
        comp.style.transition = `all ${duration / 1000}s ease-in-out`
        comp.style.transform = `scale(${scale})`
        new Promise(resolve => setTimeout(resolve, duration))
            .then(() => {
                comp.style.backgroundColor = ''
                comp.style.color = ''
                comp.style.transform = ''
                return new Promise(resolve => setTimeout(resolve, duration))
            })
            .then(() => {
                comp.style.transition = ''
            })
    }

    const pendingLocksRef = useRef<
        { sats: number; blocks: number; isUpvalue: boolean; isBoost: boolean; isLock: boolean }[]
    >([])
    const bufferTimeoutRef = useRef<number | null>(null)

    const signalType = useStore(state => state.signalType)

    const handleLockOrUpvalue = useCallback(
        (sats: number, blocks?: number, type?: SignalType) => {
            // optimistically update the ui by creating synthetic upvalues/locks
            // these will be replaced by the actual upvalues/locks after socket update
            if (type === 'upvalue' || type === 'boost') {
                const newUpvalueAmt = answer.bsv_upvalue_amt + sats
                const syntheticUpvalue = {
                    id: `synthetic-${Date.now()}`,
                    answer_id: answer.id,
                    from_user_id: currentUser.id,
                    to_user_id: answer.user_id,
                    tx_id: null, // will be set by backend after socket update
                    amount: sats,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    boost: false,
                    is_boost: type === 'boost',
                    is_synthetic: true, // mark as synthetic for UI
                }
                updateCachedAnswer(answer.id, {
                    bsv_upvalue_amt: newUpvalueAmt,
                    bsv_upvalues: [...(answer.bsv_upvalues || []), syntheticUpvalue],
                })
            } else {
                const newLockAmt = answer.bsv_lock_amt + sats
                const syntheticLock = {
                    id: `synthetic-${Date.now()}`,
                    tx_id: null, // will be set by backend
                    locking_to_tx_id: answer.bsv_tx_id,
                    amount: sats,
                    num_blocks: blocks,
                    answer_id: answer.id,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    block_height: blockheight,
                    locked_at_block: blockheight,
                    user_id: currentUser.id,
                    unlocked: false,
                    unlock_tx_id: null,
                    is_synthetic: true, // mark as synthetic for UI
                }
                updateCachedAnswer(answer.id, {
                    bsv_lock_amt: newLockAmt,
                    bsv_locks: [...(answer.bsv_locks || []), syntheticLock],
                })
            }

            // add to pending operations
            pendingLocksRef.current.push({
                sats,
                blocks,
                isUpvalue: type === 'upvalue',
                isBoost: type === 'boost',
                isLock: type === 'lock',
            })

            // clear out existing timeout if we got a new request
            if (bufferTimeoutRef.current) {
                clearTimeout(bufferTimeoutRef.current)
            }

            bufferTimeoutRef.current = window.setTimeout(
                () => processBatchedOperations(),
                LOCK_REQUEST_BATCH_WINDOW_MS,
            )
        },
        [
            answer.id,
            answer.bsv_lock_amt,
            answer.bsv_upvalue_amt,
            answer.bsv_locks,
            answer.bsv_upvalues,
            answer.bsv_tx_id,
            signalType,
        ],
    )

    const processBatchedOperations = useCallback(async () => {
        const opsToProcess = pendingLocksRef.current
        pendingLocksRef.current = []

        if (opsToProcess.length === 0) return

        const totalSats = opsToProcess.reduce((sum, op) => sum + op.sats, 0)

        if (currentUserBsvBalance < totalSats) {
            alert(
                `Not enough coins to ${
                    signalType === 'upvalue' ? 'upvalue' : 'lock'
                }. Please deposit more coins.`,
            )
            openWalletModal()
            revertOptimisticUpdates(opsToProcess)
            return
        }

        try {
            // group operations by type (lock vs upvalue/boost)
            const locks = opsToProcess.filter(op => op.isLock)
            const upvalues = opsToProcess.filter(op => op.isUpvalue || op.isBoost)

            // process locks
            if (locks.length > 0) {
                const totalLockSats = locks.reduce((sum, lock) => sum + lock.sats, 0)

                if (!answer.bsv_tx_id) {
                    const [reloadedAnswer] = await api.getAnswers([answer.id])
                    if (reloadedAnswer?.bsv_tx_id) {
                        answer.bsv_tx_id = reloadedAnswer.bsv_tx_id
                    } else {
                        throw new Error('no bsv transaction id found for answer')
                    }
                }

                const lockTx = await api.createLockTx(
                    currentUserBsvAddress,
                    answer.bsv_tx_id,
                    totalLockSats,
                    locks[0].blocks,
                )
                const lockTxId = await wallet.broadcastTx(lockTx, true)
                await api.lockAnswer(answer.id, totalLockSats, locks[0].blocks, lockTxId)
            }

            // process upvalues
            if (upvalues.length > 0) {
                const totalUpvalueSats = upvalues.reduce((sum, up) => sum + up.sats, 0)
                const isBoost = upvalues[0].isBoost
                await api.sendUpvalue(answer.id, totalUpvalueSats, isBoost)
            }
        } catch (err) {
            console.error('Error processing operations:', err)
            alert(`Error ${signalType === 'upvalue' ? 'upvaluing' : 'locking'} coins: ${err}`)
            revertOptimisticUpdates(opsToProcess)
        }
    }, [currentUserBsvBalance, answer.id, answer.bsv_tx_id, signalType, openWalletModal])

    const revertOptimisticUpdates = useCallback(
        (ops: { sats: number; blocks: number; isUpvalue: boolean }[]) => {
            const totalLockSats = ops
                .filter(op => !op.isUpvalue)
                .reduce((sum, op) => sum + op.sats, 0)
            const totalUpvalueSats = ops
                .filter(op => op.isUpvalue)
                .reduce((sum, op) => sum + op.sats, 0)

            const updates: Partial<Answer> = {}
            if (totalLockSats > 0) {
                updates.bsv_lock_amt = answer.bsv_lock_amt - totalLockSats
            }
            if (totalUpvalueSats > 0) {
                updates.bsv_upvalue_amt = answer.bsv_upvalue_amt - totalUpvalueSats
            }
            updateCachedAnswer(answer.id, updates)
        },
        [answer.id, answer.bsv_lock_amt, answer.bsv_upvalue_amt],
    )

    const isFirstRender = useRef(true)
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
        }
        animateLock(postLockRef)
    }, [answer.bsv_lock_amt, answer.bsv_upvalue_amt])

    useEffect(() => {
        return () => {
            if (bufferTimeoutRef.current) {
                clearTimeout(bufferTimeoutRef.current)
            }
        }
    }, [])

    const useSats = userBsvUnit === SATOSHI_UNIT
    const activeBitcoinStyles = showBsvModal ? styles.activeBitcoin : ''
    const activeSatsStyles = showBsvModal ? styles.activeSats : ''

    const currentUser = gon?.currentUser
    // Add state to track the active tab
    const [activeTab, setActiveTab] = useState<'upvalue' | 'boost' | 'lock' | 'settings'>(
        signalType === 'upvalue'
            ? currentUser?.id === answer.user_id
                ? 'boost'
                : 'upvalue'
            : 'lock',
    )

    const showBoost =
        signalType === 'boost' ||
        (signalType === 'upvalue' && gon.currentUser?.id === answer.user_id)

    // Add ref for the popup container
    const popupRef = useRef<HTMLDivElement>(null)
    const signalOptionsRef = useRef<HTMLDivElement>(null)
    const mouseDownRef = useRef<{ target: EventTarget | null }>({ target: null })

    useEffect(() => {
        function handleMouseDown(event: MouseEvent) {
            mouseDownRef.current.target = event.target
        }

        function handleMouseUp(event: MouseEvent) {
            // Only process if this is the same click (same target)
            if (mouseDownRef.current.target === event.target) {
                const isClickInsidePopup = popupRef.current?.contains(event.target as Node)
                const isClickOnSignalOptions = signalOptionsRef.current?.contains(
                    event.target as Node,
                )

                if (!isClickInsidePopup && !isClickOnSignalOptions) {
                    setShowBsvModal(false)
                }
            }
            mouseDownRef.current.target = null
        }

        if (showBsvModal) {
            document.addEventListener('mousedown', handleMouseDown)
            document.addEventListener('mouseup', handleMouseUp)
        }

        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [showBsvModal])

    return (
        <div className={styles.lockVoteComp}>
            <div className={styles.signalButtonContainer}>
                <SignalButton
                    signalType={signalType}
                    showBoost={showBoost}
                    useSats={useSats}
                    quickLockRef={quickLockRef}
                    upvalueSats={upvalueSats}
                    lockSats={lockSats}
                    lockBlocks={lockBlocks}
                    handleLockOrUpvalue={handleLockOrUpvalue}
                    animateLock={animateLock}
                    currentUser={currentUser}
                    answer={answer}
                />

                <div className={styles.signalOptionsButtonContainer}>
                    <SignalOptionsButton
                        signalType={signalType}
                        signalOptionsRef={signalOptionsRef}
                        onToggleModal={() => setShowBsvModal(prevState => !prevState)}
                    />
                </div>
            </div>

            {showBsvModal && (
                <SignalOptionsModal
                    popupRef={popupRef}
                    onClose={() => setShowBsvModal(false)}
                    showBoost={showBoost}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    customUpvalueSats={customUpvalueSats}
                    customLockSats={customLockSats}
                    customBlocks={customBlocks}
                    setCustomUpvalueSats={setCustomUpvalueSats}
                    setCustomLockSats={setCustomLockSats}
                    setCustomBlocks={setCustomBlocks}
                    useSats={useSats}
                    quickLockRef={quickLockRef}
                    lockBlocks={lockBlocks}
                    handleLockOrUpvalue={handleLockOrUpvalue}
                    animateLock={animateLock}
                    answer={answer}
                />
            )}
        </div>
    )
}
