import React from 'react'
import cn from 'classnames'
import { toBitcoin, toSats } from 'lib/bsv-util'
import SatsIcon from 'components/shared/SatsIcon'
import styles from './editable-amount.module.scss'

interface EditableAmountProps {
    value: number | null
    isBlockInput?: boolean
    useSats?: boolean
    onChange: (value: number | null) => void
    defaultValue?: number
    contextStyles?: string
    label?: string
}

export default function EditableAmount({
    value,
    isBlockInput = false,
    useSats = false,
    onChange,
    defaultValue = 144,
    contextStyles,
    label,
}: EditableAmountProps) {
    const editAmountClass = useSats ? styles.satsEditAmount : styles.bitcoinEditAmount

    return (
        <div className={cn(styles.container, isBlockInput && styles.blockContainer)}>
            {label && <div className={styles.inputLabel}>{label}</div>}
            <div className={cn(styles.editableAmountComp, editAmountClass, contextStyles)}>
                <span className={cn(styles.currencySymbol, !useSats && styles.bitcoinSymbol)}>
                    {isBlockInput ? (
                        <i className="fa fa-cube" />
                    ) : useSats ? (
                        <SatsIcon contextStyles={cn(styles.satsIcon)} />
                    ) : (
                        <i className="fa fa-btc" />
                    )}
                </span>

                <input
                    className={styles.amountInput}
                    type="number"
                    value={
                        typeof value === 'number'
                            ? isBlockInput
                                ? value
                                : useSats
                                ? value
                                : toBitcoin(value)
                            : ''
                    }
                    onChange={ev => {
                        const val = ev.target.value
                        if (val === '') {
                            onChange(isBlockInput ? defaultValue : null)
                        } else if (isBlockInput) {
                            const num = Number(val)
                            if (isNaN(num) || num < 1) onChange(defaultValue)
                            else onChange(num)
                        } else if (useSats) {
                            const numSats = Number(val)
                            if (isNaN(numSats) || numSats < 1) onChange(null)
                            else onChange(numSats)
                        } else {
                            const numBitcoin = Number(val)
                            if (isNaN(numBitcoin)) onChange(null)
                            else onChange(toSats(numBitcoin))
                        }
                    }}
                    placeholder={isBlockInput ? defaultValue.toString() : useSats ? '0' : '0.0'}
                />
            </div>
        </div>
    )
}
