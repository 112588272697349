import React from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import styles from '../lock-vote.module.scss'
import { SignalType } from 'types/signals'
import SatsIcon from 'components/shared/SatsIcon'
import { toBitcoin, format, SATS_BEFORE_CONFIRM } from 'lib/bsv-util'
import { Answer } from 'types/answers'

interface SignalButtonProps {
    signalType: SignalType
    showBoost: boolean
    useSats: boolean
    quickLockRef: React.RefObject<HTMLDivElement>
    upvalueSats: number
    lockSats: number
    lockBlocks: number
    handleLockOrUpvalue: (sats: number, blocks?: number, type?: SignalType) => void
    animateLock: (ref: React.RefObject<HTMLDivElement>, scale?: number) => void
    currentUser: any
    answer: Answer
}

export function SignalButton({
    signalType,
    showBoost,
    useSats,
    quickLockRef,
    upvalueSats,
    lockSats,
    lockBlocks,
    handleLockOrUpvalue,
    animateLock,
    currentUser,
    answer,
}: SignalButtonProps) {
    return (
        <div className={cn(styles.signalButtonComp, answer.bsv_lock_amt ? '' : styles.zero)}>
            <div
                ref={quickLockRef}
                className={cn(
                    styles.quickSignalButton,
                    showBoost
                        ? styles.boostQuickButton
                        : signalType === 'upvalue'
                        ? styles.upvalueQuickButton
                        : styles.lockQuickButton,
                )}
                onClick={ev => {
                    ev.stopPropagation()
                    ev.preventDefault()
                    // if it's the user's own answer upvalue needs to become boost
                    const answerSignalType =
                        answer.user_id === currentUser.id && signalType === 'upvalue'
                            ? 'boost'
                            : signalType
                    switch (answerSignalType) {
                        case 'upvalue':
                            if (upvalueSats > SATS_BEFORE_CONFIRM) {
                                useStore
                                    .getState()
                                    .actions.modalActions.openConfirmModal(
                                        `Upvalue ${toBitcoin(upvalueSats)} bitcoin?`,
                                        'Upvalue',
                                        () => handleLockOrUpvalue(upvalueSats, 0, answerSignalType),
                                    )
                            } else handleLockOrUpvalue(upvalueSats, 0, answerSignalType)
                            break
                        case 'boost':
                            if (gon.currentUser.space_options.confirmBoost)
                                handleLockOrUpvalue(upvalueSats, 0, answerSignalType)
                            else
                                useStore
                                    .getState()
                                    .actions.modalActions.openConfirmModal(
                                        'Upvaluing your own message sends bitcoin to the Treechat platform.',
                                        'Got it!',
                                        () => {
                                            handleLockOrUpvalue(upvalueSats, 0, answerSignalType)
                                            api.updateUserSpaceOptions({ confirmBoost: true })
                                            gon.currentUser.space_options.confirmBoost = true
                                        },
                                    )
                            break
                        case 'lock':
                            handleLockOrUpvalue(lockSats, lockBlocks, answerSignalType)
                            break
                    }
                    animateLock(quickLockRef, 1.1)
                }}
            >
                <Amount
                    quickSats={signalType === 'upvalue' ? upvalueSats : lockSats}
                    quickBlocks={lockBlocks}
                    signalType={signalType}
                    showBoost={showBoost}
                    useSats={useSats}
                />
            </div>
        </div>
    )
}

interface AmountProps {
    quickSats: number
    quickBlocks: number
    signalType: SignalType
    showBoost: boolean
    useSats: boolean
    contextStyles?: string
}

function Amount({
    quickSats,
    quickBlocks,
    signalType,
    showBoost,
    useSats,
    contextStyles,
}: AmountProps) {
    return (
        <div
            className={cn(
                styles.amountComp,
                showBoost
                    ? styles.boostAmount
                    : signalType === 'upvalue'
                    ? styles.upvalueAmount
                    : styles.lockAmount,
                contextStyles,
            )}
        >
            <span className={cn(styles.currencySymbol, !useSats && styles.bitcoinSymbol)}>
                {useSats ? (
                    <SatsIcon
                        contextStyles={cn(
                            styles.satsIcon,
                            signalType === 'boost' || signalType === 'upvalue'
                                ? styles.upvalueSatsIcon
                                : styles.lockSatsIcon,
                        )}
                    />
                ) : (
                    <i className="fa fa-btc" />
                )}
            </span>

            <div className={styles.quickBsv}>
                {useSats ? format(quickSats) : toBitcoin(quickSats)}
            </div>

            {signalType === 'lock' && (
                <>
                    <span className={styles.blockSymbol}>
                        <i className="fa fa-cube" />
                    </span>
                    <div className={styles.quickBlock}>{quickBlocks}</div>
                </>
            )}
        </div>
    )
}
