import React, { useState } from 'react'
import cn from 'classnames'
import styles from 'components/panels/user-control.module.scss'
import UserIcon from 'components/users/UserIcon'

export default function UserControl({ user, showUserOptions, setShowUserOptions, contextStyles }) {
    return (
        <div
            className={cn(styles.userControlComp)}
            onClick={() => setShowUserOptions(!showUserOptions)}
        >
            <UserIcon user={user} contextStyles={cn(styles.userIcon, contextStyles)} />
        </div>
    )
}
