import axios from 'axios'
import { cloneDeep, omitBy } from 'lodash'
import { accessTokens } from 'api/accessTokenMgmt'
import { getGon } from 'AppRoot'
import useStore, { DEBUG } from 'state/knovStore'
import * as cache from 'state/cache'
import { Answer } from 'types/answers'
import * as Sentry from '@sentry/react'
import { QuestId } from 'state/PanelState'
import { QuestPerms } from 'types/quests'
import { SignalType } from 'types/signals'

const commonHeaders = () => ({
    'X-CSRF-TOKEN': gon.formToken,
    'X-CLIENT-VERSION': gon.clientVersion,
    Accept: 'application/json',
    'Content-Type': 'application/json',
})

const QUERY_LEN = 0
const USE_LOCAL_CACHE = true // can be set to false for debugging

let requests = []

// TODO: investigate if this actually does anything. nothing appears to push
// into the requests aray
function abortQuestApiCall(controller) {
    const len = requests.length
    let ix = 0
    if (controller) requests.push(controller)
    while (ix < len) {
        let inProgController = requests.shift()
        inProgController.abort()
        ix = ix + 1
    }
}

function abort401(res) {
    // TODO: do something inside react when not signed in rather than redirecting
    //console.log('CHECK 401', res.status, res)
    if (res.status === 401) {
        //$('#auth-modal').modal('show')
        location.href = '/login'
        return true
    }
    return false
}

function getQueryString(params) {
    const { activeSpaceId } = useStore.getState()
    let qs = []
    if (gon.currentUser) qs.push(`space_id=${activeSpaceId}`)

    if (params) {
        for (const key in params) {
            if (Array.isArray(params[key])) {
                params[key].forEach(value => {
                    if (key) qs.push(`${key}[]=${value}`)
                })
            } else {
                if (params[key]) qs.push(`${key}=${encodeURIComponent(params[key])}`)
            }
        }
    }

    return qs.length > 0 ? '/?' + qs.join('&') : '/'
}

function userViewsQuest(questId) {
    userQuestAction(questId, 'view')
}

function userViewsBranch(quest) {
    userQuestAction(quest.id, 'view_branch')
}

async function userSearchAction(query, filter) {
    if (gon.currentUser) {
        const resp = await fetch(`${gon.api.quests}/search_actions`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({
                query,
                filter,
            }),
        })
        const data = await resp.json()
        // HACK We pretend searches are quests.
        return data?.quest
    }
}

function updateUserOptions(options) {
    if (gon.currentUser) {
        return fetch(`${gon.api.options}`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify(options),
        })
    }
}

export async function updateUserSpaceOptions(options) {
    if (gon.currentUser) {
        const userId = gon.currentUser.id
        const spaceId = useStore.getState().activeSpaceId

        fetch(`${gon.api.options}/space`, {
            method: 'PATCH',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({
                user_id: userId,
                space_id: spaceId,
                options: options,
            }),
        })
    }
}

function userQuestAction(questId, action) {
    if (gon.currentUser) {
        fetch(`${gon.api.quests}/${questId}/actions`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({
                name: action,
            }),
        })
    }
}

async function actOnNoti(questId, answerId) {
    if (gon.currentUser) {
        fetch(`${gon.api.notifications}/actions`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({
                quest_id: questId,
                answer_id: answerId,
            }),
        })
    }
}

async function createQuest(quest) {
    const { activeSpaceId } = useStore.getState()
    let rootQuest

    if (quest && quest.parent && activeSpaceId) {
        const form = new FormData()
        // We need to send the client_id to the server to filter out redundant socket messages on this client.
        form.set('client_id', gon.clientId)
        form.set('id', quest.id)
        form.set('space_id', activeSpaceId)

        if (quest.team_id) form.set('team_id', quest.team_id)
        if (quest.private) form.set('private', quest.private)
        if (quest.public) form.set('public', quest.public)
        if (quest.blog) form.set('blog', quest.blog)
        if (quest.default_sort_col) form.set('default_sort_col', quest.default_sort_col)
        if (quest.default_sort_dir) form.set('default_sort_dir', quest.default_sort_dir)
        if (quest.thread_type) form.set('thread_type', quest.thread_type)
        if (quest.created_at) form.set('created_at', quest.updated_at)
        if (quest.updated_at) form.set('updated_at', quest.updated_at)

        const parent = quest.parent
        form.set('parent_attributes[id]', parent.id)
        form.set('parent_attributes[content]', parent.content)
        if (parent.delta_json)
            form.set('parent_attributes[delta_json]', JSON.stringify(parent.delta_json))
        if (parent.embeds?.length)
            form.set('parent_attributes[embeds]', JSON.stringify(parent.embeds))
        // For some reason need to convert to array to get file upload working.
        if (parent.files?.length)
            [parent.files[0]].forEach(f => {
                form.append('parent_attributes[files][]', f)
            })
        if (parent.answer_image) form.set('parent_attributes[answer_image]', parent.answer_image)
        if (parent.recording) form.set('parent_attributes[recording]', parent.recording)
        if (parent.created_at) form.set('parent_attributes[created_at]', parent.created_at)
        if (parent.updated_at) form.set('parent_attributes[updated_at]', parent.updated_at)
        if (parent.talk_to_agent) {
            form.set('parent_attributes[talk_to_agent]', parent.talk_to_agent)
            if (parent.agent_model) form.set('parent_attributes[agent_model]', parent.agent_model)
        }

        const res = await axios.post(gon.api.quests, form, { headers: { ...accessTokens() } })
        if (res.data.quest) rootQuest = cache.cacheQuest(res.data.quest)
        //console.log('POSTED QUEST', rootQuest, rootQuest.parent, rootQuest.parent.embeds)
        //console.log('api post quest caching', rootQuest)
    }

    return rootQuest
}

async function duplicateQuest(questId) {
    const url = `${gon.api.quests}/${questId}/duplicate`
    return fetch(url, {
        method: 'put',
        headers: { ...accessTokens() },
    })
        .then(res => res.json())
        .catch(err => {
            console.log(err)
        })
}

async function deleteQuest(questId) {
    const url = `${gon.api.quests}/${questId}`

    await fetch(url, {
        method: 'delete',
        headers: { ...accessTokens() },
    }).catch(err => {
        console.log(err)
    })
}

async function postThread(content, contentJson, parentId, parentType) {
    const res = await fetch(gon.api.threads, {
        method: 'post',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': gon.formToken,
        },
        body: JSON.stringify({
            content: content,
            content_json: contentJson,
            parent_id: parentId,
            parent_type: parentType,
        }),
    })
    const data = await res.json()
    return data.quest
}

async function pinQuest(questId, teamId) {
    const url = `${gon.api.quests}/${questId}/pin`
    const res = await fetch(url, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': gon.formToken,
        },
        body: JSON.stringify({
            team_id: teamId,
        }),
    })
    //const data = await res.json()
    //return data.quest
}

async function unpinQuest(questId, teamId) {
    const url = `${gon.api.quests}/${questId}/unpin`
    const res = await fetch(url, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': gon.formToken,
        },
        body: JSON.stringify({
            team_id: teamId,
        }),
    })
    //const data = await res.json()
    //return data.quest
}

function setAnswerForm(answer: Answer): FormData {
    const form = new FormData()
    if (answer.space_id) form.set('space_id', answer.space_id)
    else {
        // TODO Bug. Why do we have to do this?
        const { activeSpaceId } = useStore.getState()
        Sentry.withScope(scope => {
            scope.setExtras({
                answer_id: answer?.id,
                user_id: gon?.currentUser?.id,
                user_name: gon?.currentUser?.name,
                backup_space_id: activeSpaceId,
            })
            Sentry.captureMessage('Missing space id in js api.')
        })
        form.set('space_id', activeSpaceId)
    }
    if (answer.id) form.set('id', answer.id)
    if (answer.quest_id) form.set('quest_id', answer.quest_id)
    if (answer.user_id) form.set('user_id', answer.user_id)
    if (answer.content) form.set('content', answer.content)
    if (answer.delta_json) form.set('delta_json', JSON.stringify(answer.delta_json))
    if ('archive' in answer) form.set('archive', answer.archive)
    if (answer.position) form.set('position', answer.position.toString())
    if (answer.embeds?.length) answer.embeds.forEach(e => form.append('embeds[]', e.id))
    if (answer.files?.length)
        [answer.files[0]].forEach(f => {
            form.append('files[]', f)
        })
    // TODO switch to images instead of answer_image
    if (answer.answer_image) form.append('images[]', answer.answer_image)
    if (answer.recording) form.set('recording', answer.recording)
    if (answer.created_at) form.set('created_at', answer.created_at.toString())
    if (answer.updated_at) form.set('updated_at', answer.updated_at.toString())

    if (answer.child_quests?.[0]?.id) form.set('child_quest_id', answer.child_quests?.[0]?.id)
    if ('talk_to_agent' in answer && answer.talk_to_agent) {
        form.set('talk_to_agent', answer.talk_to_agent.toString())
        if (answer.agent_model) form.set('agent_model', answer.agent_model)
    }

    return form
}

async function createAnswer(answer) {
    const form = setAnswerForm(answer)
    const res = await fetch(gon.api.answers, {
        headers: { ...accessTokens() },
        method: 'POST',
        body: form,
    })
    const data = await res.json()
    if (data.quest) cache.cacheQuest(data.quest)
    if (data.side_quest) cache.cacheQuest(data.side_quest)
    return cache.cacheAnswer(data.answer)
}

async function deleteAnswer(answerId) {
    const url = `${gon.api.answers}/${answerId}`

    await fetch(url, {
        method: 'delete',
        headers: { ...accessTokens() },
    }).catch(err => {
        console.log(err)
    })
}

async function updateAnswer(
    answerId: number,
    answer: Answer,
    options: { optimistic?: boolean; skip_cache?: boolean; override: boolean } = null,
) {
    const { optimistic = false, skip_cache = false } = options || {}
    const formData = setAnswerForm(answer)

    async function getter() {
        const res = await axios.put(`${gon.api.answers}/${answerId}`, formData, {
            headers: { ...accessTokens() },
        })
        const answer = res.data.answer
        if (skip_cache) return answer

        let cachedQuest, cachedSideQuest
        //console.log('api update answer caching', res.data.answer, res.data.quest)
        if (res.data.quest) cachedQuest = cache.cacheQuest(res.data.quest)
        if (res.data.side_quest) cachedSideQuest = cache.cacheQuest(res.data.side_quest)
        return [cache.cacheAnswer(res.data.answer), cachedQuest, cachedSideQuest]
    }

    if (optimistic) {
        getter()
        return cache.updateCachedAnswer(answerId, answer)
    } else {
        return await getter()
    }
}

async function getAnswers(
    answerIds: string[],
    options: { skipCache?: boolean } = { skipCache: false },
) {
    if (!answerIds || answerIds.length === 0) {
        return []
    }

    const url = `${gon.api.answers}/bulk`

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...accessTokens(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ ids: answerIds }),
        })

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        if (!options.skipCache) {
            cache.cacheAnswers(data.answers)
        }
        return data.answers
    } catch (error) {
        console.error('error fetching answers:', error)
        return []
    }
}

/**
 * hydrates a quest object with its associated answers
 * fetches all answers for the quest, including sorted answers and parent answer, then
 * replaces the answer ids in the quest object with fully hydrated answer objects
 *
 * this is to match the json structure that the rest of the frontend expects
 *
 * @param quest - the quest object to hydrate
 * @returns the hydrated quest object, or null if the input quest is null
 */
export async function hydrateAnswersIntoQuest(quest) {
    if (!quest) return null

    const allAnswerIds = [...(quest?.sorted_answer_ids || []), quest?.parent_id].filter(Boolean)
    const batchSize = 50
    let allAnswers = []

    // Fetch answers in parallel batches
    const batchPromises = []
    for (let i = 0; i < allAnswerIds.length; i += batchSize) {
        const batch = allAnswerIds.slice(i, i + batchSize)
        batchPromises.push(getAnswers(batch))
    }
    const batchAnswersArray = await Promise.all(batchPromises)
    allAnswers = allAnswers.concat(...batchAnswersArray)

    if (quest.sorted_answer_ids) {
        quest.sorted_answers = quest.sorted_answer_ids
            .map(id => allAnswers.find(answer => answer.id === id))
            .filter(Boolean)
    }

    if (quest.parent_id) {
        quest.parent = allAnswers.find(answer => answer.id === quest.parent_id)
    }

    return quest
}

async function getQuests(params, signal = null) {
    //console.log('>>> getQuests', { params })
    const queryString = getQueryString(params)
    const questsPath = gon.currentUser ? gon.api.quests : gon.api.publicQuests
    const res = await fetch(`${questsPath}` + queryString, {
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
        },
        signal,
    }).catch(err => {
        console.log(">>> Couldn't retrieve quests for query + filter: ", queryString)
    })
    // Don't need to set anything since next search or post req is out there.
    if (abort401(res) || (signal && signal.aborted)) return

    if (res) {
        const data = await res.json().catch(err => {})
        if (data && data.quests) {
            data.quests = await Promise.all(data.quests.map(hydrateAnswersIntoQuest))
        }
        return data
    }
}

async function getQuestQuery(id: QuestId) {
    //console.log('getQuest', id)
    const res = await fetch(`${gon.api.quests}/${id}`, {
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
        },
    })

    if (res.status === 401) {
        console.error('401 error in getQuestQuery', id)
        return { id: '__PRIVATE__' }
    } else {
        const data = await res.json()
        const quest = data?.quest
        return await hydrateAnswersIntoQuest(quest)
    }
}

async function getQuest(
    id: any,
    opts: { obscure?: boolean; break_cache?: boolean } = { obscure: false },
) {
    // console.log('GET QUEST', id, opts?.obscure ? 'obscure' : '')
    const { activeSpaceId } = useStore.getState()
    async function questGetter() {
        /* retrieve the quest and update knovStore w/new data */
        let params: { space_id?: any; obscure?: boolean; break_cache?: boolean } = {
            space_id: activeSpaceId,
        }
        if (opts.obscure) params.obscure = true
        if (opts.break_cache) params.break_cache = true

        const queryStringParams = new URLSearchParams(params as Record<string, string>).toString()
        const url = `${gon.api.quests}/${id}?${queryStringParams}`
        const res = await fetch(url, {
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
            },
        })
        if (res.status === 401) {
            return null
        } else {
            const data = await res.json()
            const quest = await hydrateAnswersIntoQuest(data.quest)
            return cache.cacheQuest(quest, opts)
        }
    }
    return await questGetter()
}

// AKA getSideQuest() AKA discuss_quest
async function getCommentQuest(answerId) {
    const res = await fetch(`${gon.api.answers}/${answerId}/discuss`, {
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
    })

    if (res.status === 401) {
        return null
    } else {
        const data = await res.json()
        cache.cacheAnswer(data.quest?.parent)
        return cache.cacheQuest(data.quest)
    }
}

async function getQuestPerms(questId: string, userId: string = null) {
    const query = userId ? `?${new URLSearchParams({ user_id: userId }).toString()}` : ''
    const url = `${window.knovApiUrl}/api/v1/quests/${questId}/perms${query}`
    const res = await fetch(url, {
        headers: {
            Accept: 'application/json',
        },
    })

    const data = await res.json()
    return data.can_view
}

async function updateQuestPerms(questId: string, perms: QuestPerms) {
    const url = `${window.knovApiUrl}/api/v1/quests/${questId}/perms`
    const res = await fetch(url, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
        body: JSON.stringify(perms),
    })

    const data = await res.json()
    const quest = data.quest
    if (quest) cache.cacheQuest(quest)
    return quest
}

async function updateQuest(questId, params, options) {
    const { optimistic = false } = options || {}

    async function mutater() {
        const url = `${gon.api.quests}/${questId}`
        const res = await fetch(url, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify(params),
        })
        const { quest } = await res.json()
        return cache.cacheQuest(quest)
    }

    if (optimistic) {
        mutater()
        return cache.updateCachedQuest(questId, params)
    }
    return await mutater()
}

async function getAnswer(id, params = {}): Promise<Answer> {
    const res = await fetch(`${gon.api.answers}/${id}${getQueryString(params)}`, {
        method: 'GET',
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
    })
    const { answer } = await res.json()
    return answer
}

async function getAnswerEmbeds(id) {
    const res = await axios(`${gon.api.answers}/${id}/embeds`, {
        headers: { ...accessTokens() },
    })
    return res.data.embeds
}

async function transcribeAnswer(id, identifySpeakers: boolean = false) {
    let url = `${gon.api.answers}/${id}/transcribe`
    if (!!identifySpeakers) {
        url += '?identify_speakers=true'
    }
    console.log('Sending transcribe request to url: ', url)
    const res = await axios.post(url, null, { headers: { ...accessTokens() } })
    return res.data
}

async function getHistory(params) {
    const queryString = getQueryString(params)
    const res = await fetch(`${gon.api.history}${queryString}`, {
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
        },
    })
    const data = await res.json()
    // HACK to jilter out search queries.
    const actions = data.actions
    return actions
}

async function starQuest(questId) {
    const res = await fetch(`${gon.api.quests}/${questId}/star`, {
        method: 'PUT',
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
    })
}

async function unstarQuest(questId) {
    const res = await fetch(`${gon.api.quests}/${questId}/unstar`, {
        method: 'PUT',
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
    })
}

async function getNotificationsCount(spaceId) {
    if (gon.currentUser) {
        const res = await fetch(`${gon.api.notificationsCount}?space_id=${spaceId}`, {
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
            },
        })
        const data = await res.json()
        return data.num_noti
    } else {
        return null
    }
}

async function postEmbed(containerId, embeddedId) {
    if (containerId && embeddedId) {
        const res = await fetch(`${gon.api.answers}/${containerId}/embed`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({
                embedded_id: embeddedId,
            }),
        })
        const data = await res.json()
        return data.answer
    } else {
        return null
    }
}

async function deleteEmbed(answerId, embedId) {
    const res = await fetch(`${gon.api.answers}/${answerId}/delete-embed`, {
        method: 'delete',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': gon.formToken,
        },
        body: JSON.stringify({
            embedded_id: embedId,
        }),
    }).catch(err => {
        console.log(err)
    })
}

async function mergeAnswers(containerQuestId, embeddedQuestId) {
    if (containerQuestId && embeddedQuestId) {
        const res = await fetch(`${gon.api.quests}/${containerQuestId}/merge`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({
                embedded_quest_id: embeddedQuestId,
            }),
        })
        const data = await res.json()
        return data.quest
    } else {
        return null
    }
}

/* Move an answer to a new location, leaving any child quests in place */
async function swapAnswer(srcAnswerId, tgtAnswerId, options) {
    const { optimistic = false } = options || {}

    async function mutater() {
        let result = {}
        const fetchRes = await fetch(`${gon.api.answers}/${srcAnswerId}/swap_with`, {
            method: 'put',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: JSON.stringify({ tgt_answer_id: tgtAnswerId }),
        })
        const resOK = handleErrors(fetchRes)
        result = await resOK.json()
        // NOTE: cacheQuest() takes care of updating cached answers as well
        result?.updated_quests?.forEach(q => {
            cache.cacheQuest(q)
        })
        return result
    }

    if (optimistic) {
        const { quests, answers } = useStore.getState()

        const srcAnswer = cloneDeep(answers[srcAnswerId])
        const srcQuest = cloneDeep(
            quests[srcAnswer?.quest_id] ||
                Object.values(quests).find(q => q?.parent?.id == srcAnswer?.id),
        )
        const tgtAnswer = cloneDeep(answers[tgtAnswerId])
        const tgtQuest = cloneDeep(
            quests[tgtAnswer?.quest_id] ||
                Object.values(quests).find(q => q?.parent?.id == tgtAnswer?.id),
        )

        cache.updateCachedAnswer(srcAnswerId, {
            quest_id: tgtAnswer?.quest_id,
            position: tgtAnswer?.position,
            side_quest_id: tgtAnswer?.child_quests?.[0]?.id || null,
        })
        cache.updateCachedAnswer(tgtAnswerId, {
            quest_id: srcAnswer?.quest_id,
            position: srcAnswer?.position,
            side_quest_id: srcAnswer?.child_quests?.[0]?.id || null,
        })

        mutater()
    } else {
        return await mutater()
    }
}

async function attachVideo(answerId, blob) {
    let form = new FormData()
    form.append('recording', blob)
    form.append('id', answerId)

    const res = await fetch(`${gon.api.answers}/${answerId}/video`, {
        method: 'post',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            'X-CSRF-Token': gon.formToken,
        },
        body: form,
    })

    const data = await res.json()
    return data.url
}

async function getUsers(params = {}) {
    const { activeSpaceId } = useStore.getState()
    // TODO don't get the space from currentUser
    if (gon?.currentUser && !Object.keys(params).includes('space_id')) {
        params = { ...params, space_id: activeSpaceId }
    }
    const res = await fetch(`${gon.api.users}?${new URLSearchParams(params)}`, {
        headers: { ...accessTokens() },
    })
    const { users } = await res.json()
    return users
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText)
    }
    return response
}

async function updateUser(id, params) {
    try {
        let form
        if (params instanceof FormData) {
            form = params
        } else {
            form = new FormData()
            for (let key in params) {
                form.set(key, params[key])
            }
        }

        const res = await fetch(`${gon.api.users}/${id}`, {
            method: 'PATCH',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
                'X-CSRF-Token': gon.formToken,
            },
            body: form,
        })

        const data = await res.json()
        return data
    } catch (e) {
        return e
    }
}

export interface ILinkPreviewInfo {
    title?: string
    description?: string
    image?: string
    url: string
}

export async function getBsvLocks(answerId: string) {
    try {
        const res = await fetch(`${gon.api.answers}/${answerId}/bsv_locks`, {
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
            },
        })
        if (!res.ok) {
            throw Error(res.statusText)
        }
        const data = await res.json()
        return data
    } catch (e) {
        return e
    }
}

export async function getLinkPreview(url): Promise<ILinkPreviewInfo> {
    const res = await fetch(`${gon.api.linkpreview}?url=${encodeURIComponent(url)}`, {
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
        },
    })
        .then(r => r.json())
        .catch(err => {
            // console.log('linkpreviewcontroller failed to retrieve preview for url', url, 'error: ', err)
        })
    return await res
}

export async function getSpace(spaceId, params) {
    const res = await fetch(`${gon.api.spaces}/${spaceId}?${new URLSearchParams(params)}`, {
        headers: { ...accessTokens() },
    })
    const data = await res.json()
    return data?.space
}

export async function getSpaces(params) {
    const query = params ? `?${new URLSearchParams(params)}` : ''
    const res = await fetch(`${gon.api.spaces}${query}`, {
        headers: { ...accessTokens() },
    })
    const data = await res.json()
    return data ? data.spaces : []
}

export async function getTeams(params) {
    const query = params ? `?${new URLSearchParams(params)}` : ''
    const res = await fetch(`${gon.api.teams}${query}`, {
        headers: { ...accessTokens() },
    })
    const data = await res.json()
    return data ? data.teams : []
}

export async function getTeam(teamId) {
    return (
        await fetch(`${gon.api.teams}/${teamId}`, {
            headers: {
                ...accessTokens(),
            },
        })
    ).json()
}

async function updateTeam(teamId, params) {
    try {
        let form
        if (params instanceof FormData) {
            form = params
        } else {
            form = new FormData()
            for (let key in params) {
                form.set(key, params[key])
            }
        }

        const res = await fetch(`${gon.api.teams}/${teamId}`, {
            method: 'put',
            credentials: 'same-origin',
            headers: {
                ...accessTokens(),
            },
            body: form,
        })

        const resOK = handleErrors(res)
        const data = await resOK.json()
        return data.team
    } catch (e) {
        return e
    }
}

async function deleteInvite(email, teamId) {
    const res = await fetch(`${gon.api.teams}/${teamId}/delete-invite`, {
        method: 'DELETE',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
    })

    const resOK = handleErrors(res)
    const data = await resOK.json()
    return data.team
}

async function addToTeam(
    { emails = [], ids = [], names = [] }: { emails?: string[]; ids?: number[]; names?: string[] },
    teamId,
) {
    const body = {
        by_email: emails,
        by_id: ids,
        by_name: names,
    }
    const res = await fetch(`${gon.api.teams}/${teamId}/invite`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': gon.formToken,
        },
        body: JSON.stringify(body),
    })
    return res.json()
}

async function removeFromTeam(teamId, userId) {
    const queryParams = new URLSearchParams({
        user_id: userId,
    })
    const res = await fetch(`${gon.api.teams}/${teamId}/remove-user?${queryParams}`, {
        method: 'DELETE',
        headers: {
            ...commonHeaders(),
            ...accessTokens(),
        },
    })
    const data = await res.json()
    return data.team
}

async function createSpaceInvitation(
    space_id,
    {
        emails = [],
        ids = [],
        names = [],
        standing = false,
    }: { emails?: string[]; ids?: string[]; names?: string[]; standing?: boolean },
) {
    const body = {
        space_id,
        by_email: emails,
        by_id: ids,
        by_name: names,
        standing,
    }
    const res = await fetch(`${gon.api.space_invitations}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            ...commonHeaders(),
            ...accessTokens(),
        },
        body: JSON.stringify(body),
    })
    return (await res.json()).space
}

async function getSpaceInvitationByToken(token) {
    const res = await fetch(`${window.knovApiUrl}/api/v1/space_invitation/${token}`, {
        headers: { ...commonHeaders(), ...accessTokens() },
    })
    if (res.ok) return (await res.json()).space_invitation
    else return null
}

async function acceptSpaceInvitation(invitationId) {
    const res = await fetch(`${gon.api.space_invitations}/${invitationId}/accept`, {
        method: 'POST',
        headers: { ...commonHeaders(), ...accessTokens() },
    })
    const data = await res.json()
    return data
}

async function deleteSpaceInvitation(invitationId) {
    const res = await fetch(`${gon.api.space_invitations}/${invitationId}`, {
        headers: { ...commonHeaders, ...accessTokens() },
        method: 'DELETE',
    })
    return (await res.json()).space
}

async function removeFromSpace(spaceId, userId) {
    const res = await fetch(`${gon.api.spaces}/${spaceId}/remove-user?user_id=${userId}`, {
        headers: { ...commonHeaders, ...accessTokens() },
        method: 'DELETE',
    })
    return (await res.json()).space
}

async function updateSpace(spaceId, params) {
    let form
    if (params instanceof FormData) {
        form = params
    } else {
        form = new FormData()
        for (let key in params) {
            form.set(key, params[key])
        }
    }

    const res = await fetch(`${gon.api.spaces}/${spaceId}`, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            ...accessTokens(),
        },
        body: form,
    })
    const { space } = await res.json()
    return space
}

async function getBsvBalance() {
    const res = await fetch(`${gon.api.bsv}/balance`, {
        method: 'GET',
        headers: { ...commonHeaders(), ...accessTokens() },
    })
    const data = await res.json()
    return data
}

async function getBsvAddress() {
    const res = await fetch(`${gon.api.bsv}/address`, {
        method: 'GET',
        headers: { ...commonHeaders(), ...accessTokens() },
    })
    const data = await res.json()
    return data
}

async function sendBsv(destinationAddress, amountSats) {
    const url = `${gon.api.bsv}/send`
    return fetch(url, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            destination_address: destinationAddress,
            amount_sats: amountSats,
        }),
    })
        .then(res => res.json())
        .catch(err => {
            console.log(err)
        })
}

async function sendUpvalue(answerId: string, amountSats: number, isBoost?: boolean) {
    const url = `${gon.api.answers}/${answerId}/bsv_upvalue`
    return fetch(url, {
        method: 'POST',
        headers: {
            ...commonHeaders(),
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            omitBy(
                {
                    id: answerId,
                    sats: amountSats,
                    is_boost: isBoost,
                },
                value => value === undefined,
            ),
        ),
    }).then(res => res.json())
}

async function broadcastBsvTx(rawTx: string, fund: boolean = false) {
    const url = `${gon.api.bsv}/broadcast_tx`
    return fetch(url, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omitBy({ raw_tx: rawTx, fund }, value => !value)),
    })
        .then(res => res.json())
        .catch(err => {
            console.log(err)
        })
}

async function ensureBsvWallet() {
    const res = await fetch(`${gon.api.bsv}/ensure-wallet`, {
        method: 'GET',
        headers: { ...commonHeaders(), ...accessTokens() },
    })
    const data = await res.json()
    return data
}

async function downloadBsvWallet() {
    const response = await fetch(`${gon.api.bsv}/download-wallet`, {
        method: 'GET',
        headers: { ...commonHeaders(), ...accessTokens() },
    })
    if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
        return await response.blob()
    } else {
        throw new Error('Failed to download wallet')
    }
}

interface IShareDestinationParams {
    type: 'stream' | 'recent_thread'
    id?: string
    name?: string
}

async function clipLink({
    url,
    image,
    video,
    file,
    title,
    content,
    extraContent,
    destination,
}: {
    url?: string
    image?: Uint8Array
    video?: Uint8Array
    file?: Uint8Array
    title?: string
    extraContent?: string
    content?: string
    destination?: IShareDestinationParams
}) {
    // TODO: refactor backend endpoint and this to use normal json encodings
    // instead of all this form encoded stuff
    let form = new FormData()

    if (url) {
        form.append('quest[answers_attributes][0][url_attributes][address]', url)
        form.append('quest[answers_attributes][0][url_attributes][title]', title ? title : '')
    } else if (title) {
        form.append('quest[answers_attributes][0][url_attributes][title]', title ? title : '')
    }

    if (image) {
        let blob = new Blob([image])
        form.append('quest[answers_attributes][0][images]', blob)
    }
    if (video) {
        let blob = new Blob([video])
        form.append('quest[answers_attributes][0][recording]', blob)
    }
    if (file) {
        let blob = new Blob([file])
        form.append('quest[answers_attributes][0][files]', blob)
    }

    if (content) form.append('quest[answers_attributes][0][content]', content)

    if (destination) {
        form.append('destination[type]', destination.type)
        if (destination.id) form.append('destination[id]', destination.id.toString())
    }

    const res = await fetch(`${window.knovApiUrl}/plugin_new/clip`, {
        method: 'POST',
        headers: {
            ...accessTokens(),
        },
        body: form,
    })
        .then(response => response.json())
        .catch(err => {
            console.error(err)
            // debugPopover(`err! ${err}\n${err.stack}`)
        })
    return res
}

async function upvoteAnswer(answerId: string) {
    const res = await fetch(`${window.knovApiUrl}/votes/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...accessTokens(),
        },
        body: JSON.stringify({
            value: 1,
            votable_id: answerId,
            votable_type: 'Answer',
        }),
    })
    const data = await res.json()
    if (data.success) {
        const answer = data.votable
        cache.cacheAnswer(answer)
        return answer
    } else return null
}

async function getBsvLockAmtForAnswer(answerId) {
    const url = `${gon.api.answers}/${answerId}/get_bsv_lock_amt`
    return fetch(url, {
        method: 'GET',
        headers: { ...accessTokens() },
    })
        .then(res => res.json())
        .catch(err => {
            console.log(err)
        })
}

/**
 * creates and returns a raw lock tx without signing or paying for it
 */
async function createLockTx(
    walletAddress: string,
    lockToTxId: string,
    amtToLock: number,
    blocksToLockFor: number,
    privateKeyWIF?: string,
    paymail?: string,
    appName?: string,
    sign?: boolean,
): Promise<string> {
    // validate required parameters
    if (!walletAddress || !lockToTxId || !amtToLock || !blocksToLockFor) {
        throw new Error(
            `Missing required parameters: ${[
                !walletAddress && 'walletAddress',
                !lockToTxId && 'lockToTxId',
                !amtToLock && 'amtToLock',
                !blocksToLockFor && 'blocksToLockFor',
            ]
                .filter(Boolean)
                .join(', ')} must be provided.`,
        )
    }

    const options = {
        paymail: paymail ?? walletAddress,
        appName: appName ?? 'treechat',
        privateKeyWIF: sign ? privateKeyWIF : '',
    }
    const response = await fetch(`${gon.api.bsv}/create_lock_tx/${lockToTxId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...accessTokens(),
        },
        body: JSON.stringify({
            wallet_address: walletAddress,
            amt_to_lock: amtToLock,
            blocks_to_lock_for: blocksToLockFor,
            paymail: options.paymail,
            app_name: options.appName,
            // private_key_wif: options.privateKeyWIF,
            // sign: sign,
        }),
    })
    const result = await response.json()
    if (response.ok) {
        return result?.raw_tx
    } else {
        throw new Error(result.error || 'Failed to create lock transaction')
    }
}

async function lockAnswer(answerId, sats, blocks, lockTxId) {
    const url = `${gon.api.answers}/${answerId}/bsv_lock`
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sats,
            blocks,
            lock_tx_id: lockTxId,
        }),
    })
    const data = await res.json()
    if (data.status === 'lock created') {
        cache.cacheAnswer(data?.answer)
        return data.answer
    } else {
        throw new Error(data.error)
    }
}

async function postToBsv(answerId) {
    const url = `${gon.api.answers}/${answerId}/post-to-bsv`
    return fetch(url, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
    })
        .then(async res => {
            const data = await res.json()
            if (data.status === 'complete') {
                cache.cacheAnswer(data.answer)
            }
            return data
        })
        .catch(err => {
            console.log(err)
        })
}

async function getUserByPandaPubkey(pubKey) {
    const url = `${gon.api.users}/by-panda-pubkey/${pubKey}`
    return fetch(url, {
        method: 'GET',
        headers: {
            ...accessTokens(),
        },
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

async function setPandaPubkey(userId, pubkey) {
    const url = `${gon.api.users}/set-panda-pubkey`
    return fetch(url, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            pubkey,
        }),
    }).catch(err => console.log(err))
}

async function muteUser(userId: string) {
    return fetch(`${gon.api.mutes}`, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
        body: JSON.stringify({ user_id: userId }),
    })
}

async function unmuteUser(userId: string) {
    return fetch(`${gon.api.mutes}/${userId}`, {
        method: 'DELETE',
        headers: {
            ...accessTokens(),
            ...commonHeaders(),
        },
    })
}

async function createFragmentWalletTx(
    numFragments: number = 10,
    fragmentPercentage: number = 1.0,
    broadcast: boolean = true,
) {
    const url = `${gon.api.bsv}/create_fragment_wallet_tx`
    return fetch(url, {
        method: 'POST',
        headers: {
            ...accessTokens(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            omitBy(
                {
                    num_fragments: numFragments,
                    fragment_percentage: fragmentPercentage,
                    broadcast,
                },
                value => value === undefined,
            ),
        ),
    })
        .then(res => res.json())
        .catch(err => {
            console.error('Error creating fragment wallet tx:', err)
            throw err
        })
}

async function getBlockHeight() {
    const url = `${gon.api.bsv}/blockheight`
    return fetch(url, {
        method: 'GET',
        headers: { ...accessTokens() },
    })
        .then(res => res.json())
        .catch(err => {
            console.error('Error getting block height:', err)
            throw err
        })
}

async function getBsvExchangeRate() {
    const url = `${gon.api.bsv}/exchangerate`
    return fetch(url, {
        method: 'GET',
        headers: { ...accessTokens() },
    })
        .then(res => res.json())
        .catch(err => {
            console.error('Error getting bsv exchange rate:', err)
            throw err
        })
}

const toExport = {
    // Api.
    createQuest,
    deleteQuest,
    duplicateQuest,
    starQuest,
    unstarQuest,
    postThread,
    transcribeAnswer,
    upvoteAnswer,
    getQuests,
    getQuest,
    getQuestQuery,
    getQuestPerms,
    updateQuestPerms,
    getCommentQuest,
    updateQuest,
    getAnswer,
    getAnswers,
    getAnswerEmbeds,
    createAnswer,
    deleteAnswer,
    updateAnswer,
    swapAnswer,
    attachVideo,
    postEmbed,
    deleteEmbed,
    getHistory,
    getNotificationsCount,
    getUsers,
    getSpace,
    getSpaces,
    // Util.
    commonHeaders,
    getLinkPreview,
    getQueryString,
    abortQuestApiCall,
    actOnNoti,
    mergeAnswers,
    // Actions.
    userViewsQuest,
    userViewsBranch,

    pinQuest,
    unpinQuest,
    updateUser,
    userSearchAction,
    updateUserOptions,
    updateUserSpaceOptions,
    updateTeam,
    addToTeam,
    removeFromTeam,
    getTeams,
    getTeam,
    createSpaceInvitation,
    getSpaceInvitationByToken,
    acceptSpaceInvitation,
    deleteSpaceInvitation,
    removeFromSpace,
    updateSpace,
    getGon,
    clipLink,
    getBsvLockAmtForAnswer,
    getBsvLocks,
    lockAnswer,
    getBsvBalance,
    getBsvAddress,
    postToBsv,
    sendBsv,
    sendUpvalue,
    ensureBsvWallet,
    downloadBsvWallet,
    broadcastBsvTx,
    createLockTx,
    getUserByPandaPubkey,
    setPandaPubkey,
    muteUser,
    unmuteUser,
    createFragmentWalletTx,
    getBlockHeight,
    getBsvExchangeRate,
}

if (DEBUG) {
    window.api = toExport
}

export default toExport
