import React from 'react'

export default function SatsIcon({
    color = '#ffab46',
    width = 12,
    height = 15,
    strokeWidth = 6,
    lineWidth = 12,
    contextStyles,
}) {
    return (
        <div className={contextStyles}>
            {false && (
                <svg
                    width="129"
                    height="150"
                    viewBox="0 0 129 150"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M93.248 98.592C93.248 106.443 90.6027 112.544 85.312 116.896C80.2773 121.419 70.6187 123.5 62 123.5C59.0987 123.5 58.7733 123.339 55.36 122.656C51.9467 121.973 48.7467 120.992 45.76 119.712C44.3093 120.139 42.8587 118.048 41.408 118.56C40.0427 119.072 38.6947 117.365 37.5 118.048C35.964 113.952 35.6213 117.621 34 113.696C32.464 109.771 37.536 118.432 36 114.336C39.328 116 39.104 116.768 39.616 116.512C40.128 116.171 43.1147 116.768 43.712 116.512C39.7867 113.611 47.8933 123.253 43.712 116.512C44.992 117.536 48.2933 117.707 50 118.56C51.792 119.413 52.1227 118.403 54 119C55.8773 119.683 55.9147 118.219 57.792 118.56C59.6693 118.901 62.912 118.56 64.448 118.56C67.6907 118.56 70.592 118.133 73.152 117.28C75.712 116.427 77.8453 115.232 79.552 113.696C83.136 110.368 84.928 105.973 84.928 100.512C84.928 98.464 84.4587 96.4587 83.52 94.496C82.5813 92.448 81.344 90.784 79.808 89.504C76.3947 86.7733 69.696 83.7013 59.712 80.288C51.776 77.472 45.504 74.1866 40.896 70.432C34.752 65.7387 31.68 60.0213 31.68 53.28C31.68 46.368 34.1547 40.8213 39.104 36.64C43.8827 32.4586 50.112 30.368 57.792 30.368C61.4613 30.368 65.0453 30.88 68.544 31.904C69.4827 32.2453 70.3787 32.5866 71.232 32.928C72.1707 33.2693 73.024 33.6106 73.792 33.952L81.472 31.392C83.008 35.3173 84.544 39.2 86.08 43.04C87.616 46.88 89.152 50.7626 90.688 54.688C89.7493 55.1146 88.8107 55.584 87.872 56.096C84.288 49.952 80.192 45.088 75.584 41.504C70.0373 37.408 64.064 35.36 57.664 35.36C55.4453 35.36 53.3973 35.7013 51.52 36.384C49.6427 36.9813 47.936 37.792 46.4 38.816C44.2667 40.2666 42.6027 42.1013 41.408 44.32C40.2133 46.5386 39.616 49.1413 39.616 52.128C39.616 56.992 42.1333 61.0453 47.168 64.288C49.728 65.824 52.2027 67.1466 54.592 68.256C56.9813 69.28 60.0533 70.3893 63.808 71.584C69.0133 73.2906 73.408 75.04 76.992 76.832C80.576 78.624 83.4347 80.3733 85.568 82.08C90.688 86.176 93.248 91.68 93.248 98.592Z"
                        fill="black"
                    />
                    <path
                        d="M73 133C83 135.5 126.5 123 126.5 73C126.5 35.5 100 9 61.5 9C29.5 9 3 29.5 3 67.5C3 95.6281 26.4513 117.185 54 120.544C54 120.544 64.4763 120.544 67 120.544V150M67 0V16.5V33"
                        stroke="black"
                        strokeWidth="5"
                    />
                </svg>
            )}

            {true && (
                <svg
                    width="61"
                    height="124"
                    viewBox="0 0 61 124"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M60.408 82.656C60.408 88.032 59.0853 92.7253 56.44 96.736C53.7947 100.747 49.9547 103.861 44.92 106.08C39.9707 108.213 33.912 109.28 26.744 109.28C23.5867 109.28 20.472 109.067 17.4 108.64C14.4133 108.213 11.512 107.616 8.696 106.848C5.96533 105.995 3.36267 104.971 0.888 103.776V85.728C5.24 87.6053 9.72 89.3547 14.328 90.976C19.0213 92.512 23.672 93.28 28.28 93.28C31.4373 93.28 33.9547 92.8533 35.832 92C37.7947 91.1467 39.2027 89.9947 40.056 88.544C40.9947 87.0933 41.464 85.4293 41.464 83.552C41.464 81.248 40.6533 79.2853 39.032 77.664C37.496 76.0427 35.4053 74.5493 32.76 73.184C30.1147 71.7333 27.0853 70.1973 23.672 68.576C21.5387 67.552 19.2347 66.3573 16.76 64.992C14.2853 63.5413 11.896 61.792 9.592 59.744C7.37333 57.6107 5.53867 55.0507 4.088 52.064C2.63733 49.0773 1.912 45.4933 1.912 41.312C1.912 35.8507 3.14933 31.2 5.624 27.36C8.184 23.4347 11.768 20.448 16.376 18.4C21.0693 16.352 26.5733 15.328 32.888 15.328C37.6667 15.328 42.1893 15.8827 46.456 16.992C50.808 18.1013 55.3307 19.68 60.024 21.728L53.752 36.832C49.5707 35.1253 45.816 33.8027 42.488 32.864C39.16 31.9253 35.7467 31.456 32.248 31.456C29.8587 31.456 27.8107 31.84 26.104 32.608C24.3973 33.376 23.0747 34.4427 22.136 35.808C21.2827 37.1733 20.856 38.7947 20.856 40.672C20.856 42.8053 21.496 44.64 22.776 46.176C24.056 47.6267 25.976 49.0347 28.536 50.4C31.096 51.7653 34.296 53.3867 38.136 55.264C42.8293 57.4827 46.7973 59.7867 50.04 62.176C53.368 64.5653 55.928 67.3813 57.72 70.624C59.512 73.8667 60.408 77.8773 60.408 82.656Z" />
                    <line x1="31" x2="31" y2="16" strokeWidth="14" />
                    <line x1="31" y1="108" x2="31" y2="124" strokeWidth="14" />
                </svg>
            )}
        </div>
    )
}
